import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  API_URL: String = environment.ENV_URL + environment.FORWARD_SLASH + environment.UAA_SERVICE_PREFIX;
  ORDER_URL: String = environment.ENV_URL + environment.FORWARD_SLASH + environment.ORDER_SERVICE_PREFIX;
  CREATE_TOKEN = this.API_URL + environment.API_CREATE_TOKEN;
  VIEW_TOKEN = this.API_URL + environment.API_VIEW_TOKEN;
  LINK_TOKEN = this.ORDER_URL + environment.API_LINK_TOKEN;

  constructor(private httpClient: HttpClient) { }

  createAccessToken(data) {
    return this.httpClient.post(this.CREATE_TOKEN, data);
  }

  viewToken(token) {
    return this.httpClient.get(this.VIEW_TOKEN + '?token=' + token);
  }

  generateLinkToken(token, orderNumber) {
    return this.httpClient.get(this.LINK_TOKEN + '?token=' + token + '&orderNumber=' + orderNumber);
  }

  setHeader(token) {
    var header = {
      headers: new HttpHeaders()
        .set('Authorization', `External ${token}`)
    }
    return header;
  }

  getOrderDetailMetaData(token) {
    let header = this.setHeader(token);
    return this.httpClient.get(this.ORDER_URL + "table/definition?tables=v_orderItemDetail", header);
  }

  getOrderDetail(salesOrderNumber, status, token) {
    let header = this.setHeader(token);
    return this.httpClient.get(this.ORDER_URL + "order_item?salesOrderNumber=" + salesOrderNumber + "&status=" + status, header);
  }

  getTMSOrderDetails(orderNumber: any, token) {
    let header = this.setHeader(token);
    return this.httpClient.get(this.ORDER_URL + "stops/tms?orderNumber=" + orderNumber, header);
  }

  getChildOrderDetails(orderNumber: any) {
    return this.httpClient.get(this.ORDER_URL + "split_order?id=" + orderNumber);
  }
}
