import { Component, OnInit, NgZone, HostListener } from '@angular/core';
import { OktaAuthService } from '@okta/okta-angular';
import { environment } from 'src/environments/environment';
import { TemplateService } from '../../adapter/service/template.service';
import { GlobalService } from '../../adapter/service/global.service';
import { AuthService } from '../../adapter/service/auth.service';
import { Router } from '@angular/router';
import { Okta } from '../../adapter/model/okta';
import { HttpClient } from '@angular/common/http';
import { StorageService } from '../../service/storage.service';

@Component({
  selector: 'app-new-login',
  templateUrl: './new-login.component.html',
  styleUrls: ['./new-login.component.scss']
})
export class NewLoginComponent implements OnInit {

  signIn;
  showWidget = true;
  widget;
  isOktaActive = true;
  loginError = false;
  userName = '';
  passwd = '';
  mobile=false
  year:any=2020
  APPLICATION_IS_OKTA_ENABLED = environment.ENV_URL + environment.FORWARD_SLASH + environment.UAA_SERVICE_PREFIX + environment.APPLICATION_OKTA_BOOLEAN

  constructor(oktaAuth: OktaAuthService, 
              public authService: AuthService,
              public router: Router, 
              public okta: Okta,
              public globalService: GlobalService, 
              private templateService: TemplateService, 
              private http: HttpClient, 
              public ngZone: NgZone,
              private storageService: StorageService) {
       this.signIn = oktaAuth;
    }

  toggleWidget() {
    this.showWidget = !this.showWidget;
  }

  @HostListener("window:resize", ['$event'])
  onResize(event) {
    var width = window.innerWidth;
    if (width < 768) {
      this.mobile = true;
    } else {
        this.mobile = false;
    }
  }
  
  ngOnInit() {
    this.storageService.clearAllItems();
    var width = window.innerWidth;
    if (width < 768) {
      this.mobile = true;
    } else {
      this.mobile = false;
    }
      this.http.get(this.APPLICATION_IS_OKTA_ENABLED).subscribe((val:any)=>{
        this.storageService.setItem("isOktaActive",val.enabled);
        this.isOktaActive = (val.enabled == 'true');
        this.signIn.logout();
        if(this.isOktaActive){
          this.widget = this.okta.getWidget();
          this.initializeOktaWidget();
        }
      });
  }

  initializeOktaWidget() {
    this.widget.renderEl({
      el: '.okta-widget',
      els:'.okta-widget'},
      (res) => {
        if (res.status === 'SUCCESS') {
          this.http.post(this.globalService.apiRoot + 'token',
          {
            'type': 'idToken',
            'oktaToken': res.idToken
          })
          .subscribe(
            (val:any) => {
              if(val.hasError === true) {
                this.globalService.addAlert('danger',val.message);
                this.widget.remove();
                this.widget = this.okta.getWidget();
                this.initializeOktaWidget();
              }else this.login(val);
            });
        }
      },
      (err) => {
        throw err;
      });
  }

  login(res) {
    this.authService.login(res);
    this.templateService.refreshTheView();
    if(this.isOktaActive)
      this.widget.remove();
    this.ngZone.run(() => this.router.navigate(['/home'])).then();
  }

  loginWithUserName() {
    this.loginError = false;
    this.http.post(this.globalService.apiRoot + 'login', 
    {
      'email': this.userName,
      'password': this.passwd
     })
      .subscribe(
        (res:any) => {
          if(res.hasError === true) {
            this.loginError = true;
          } else this.login(res);
        });
  }


}
