import { Component, OnInit } from '@angular/core';
import { GlobalService } from './core/adapter/service/global.service';
import { Router, NavigationStart, NavigationEnd, NavigationCancel, NavigationError, Event } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  constructor(public globalService: GlobalService, private router: Router) {
    //temporary code to avoid confusion with localstorage encyption
    const token = localStorage.getItem('userToken');
     if(token != null && token.startsWith("ey")) {
        localStorage.clear();
     } 

      this.router.events.subscribe((event: Event) => {
        switch (true) {
          case event instanceof NavigationStart: {
            this.globalService.showLoader();
            break;
          }
          case event instanceof NavigationEnd: {
            this.globalService.header = this.globalService.displayHeader;
          }
          case event instanceof NavigationCancel:
          case event instanceof NavigationError: {
            this.globalService.hideLoader();
            break;
          }
          default: {
            break;
          }
        }
      });
    }
}
