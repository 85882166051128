import { AuthInterceptor } from './core/adapter/service/auth.interceptor.service';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule, ErrorHandler } from '@angular/core';
import { AppRoutingModule } from './app.routing.module';
import { AppComponent } from './app.component';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { CustomAlertComponent } from './core/view/alert/alert.component';
import { AlertModule, ModalModule, CollapseModule } from 'ngx-bootstrap';
import {
  OktaAuthModule
  // OktaCallbackComponent,
  // OktaAuthGuard
} from '@okta/okta-angular';
import { LoginWithRedirectComponent } from './core/view/login-with-redirect/login.with.redirect.component';
import { FormsModule } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { LoaderComponent } from './core/view/loader/loader.component';
import { NewLoginComponent } from './core/view/new-login/new-login.component';
import { OrderDetailsComponent } from './core/view/api/order-details/order-details.component';
import { DatasetModule } from './core/view/dataset/server/dataset.module';
import { DatePipe } from '@angular/common';
import { GlobalErrorHandler } from './core/adapter/service/global-error-handler';



const config = {
  issuer: environment.OKTA_ISSUER,
  redirectUri: environment.OKTA_REDIRECT_URL,
  clientId: environment.OKTA_CLIENT_ID,
  pkce: environment.OKTA_PKCE
};

@NgModule({
  declarations: [
    AppComponent,
    NewLoginComponent,
    CustomAlertComponent,
    LoginWithRedirectComponent,
    LoaderComponent,
    OrderDetailsComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    FormsModule,
    CarouselModule.forRoot(),
    BsDropdownModule.forRoot(),
    AlertModule.forRoot(),
    OktaAuthModule.initAuth(config),
    ModalModule.forRoot(),
    CollapseModule.forRoot(),
    DatasetModule
  ],
  providers: [DatePipe,{ provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },{provide: ErrorHandler, useClass: GlobalErrorHandler}],
  bootstrap: [AppComponent]
})
export class AppModule { }
