import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginWithRedirectComponent } from './core/view/login-with-redirect/login.with.redirect.component';
import { LoginGuard } from './core/guard/login.guard.service';
import { NewLoginComponent } from './core/view/new-login/new-login.component';
import { OrderDetailsComponent } from './core/view/api/order-details/order-details.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/home/dashboard',
    pathMatch: 'full'
  },
  {
    path: 'login',
    component: NewLoginComponent
  },
  {
    path: 'api',
    loadChildren: './core/view/api/api.module#ApiModule'
  },
  {
    path: 'login_with_redirect',
    component: LoginWithRedirectComponent
  },
  {
    path: 'home',
    canActivateChild: [LoginGuard],
    loadChildren: './application/application.module#ApplicationModule'
  },
  {
    path: 'order_detail_api',
    component: OrderDetailsComponent
  }

];

@NgModule({
  imports: [RouterModule.forRoot(routes
)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
