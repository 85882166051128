export class StopDTO {
    stopNumber: string;
    isStopDelivered: boolean;
    scheduled: string;
    arrived: string;
    address: string;
    customerName: string;

    constructor(stopNumber?: string, isStopDelivered?: boolean, scheduled?: string, arrived?: string, address?: string, customerName?: string) {
        this.stopNumber = stopNumber;
        this.isStopDelivered = isStopDelivered;
        this.scheduled = scheduled;
        this.arrived = arrived;
        this.address = address;
        this.customerName = customerName;
    }
}