import { Injectable } from '@angular/core';
import { GlobalService } from './global.service';
import { StorageService } from '../../service/storage.service';

@Injectable({
  providedIn: 'root',
 })

export class AuthService {
  isLoggedIn: boolean;

  redirectUrl: any;
  constructor(public globalService:GlobalService,
              private storageService: StorageService) 
  {
    const token = this.storageService.getItem('userToken');
    if (token == null) {
      this.isLoggedIn = false;
    } else {
      this.isLoggedIn = true;
    }
  }

  login(res): void {
    this.storageService.setItem('userToken', res.token);
    this.storageService.setItem('loggedInUser', res.userId);
    this.storageService.setItem('refreshToken',res.refreshToken);
    this.isLoggedIn = true;
    this.globalService.auditLogDetails.auditType = "user";
    this.globalService.auditLogDetails.eventType = "Login Successful";
    this.globalService.saveAuditLog(this.globalService.auditLogDetails);
  }

  logout(): void {
    this.globalService.auditLogDetails.auditType = "user";
    this.globalService.auditLogDetails.eventType = "Logged out";
    this.globalService.saveAuditLog(this.globalService.auditLogDetails);
    this.storageService.clearAllItems();
    this.isLoggedIn = false;
  }
}
