import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';

@Injectable({
	providedIn: 'root'
})
export class StorageService { 

	secretKey = 'RgUkXp2s5v8y/B?E(G+KbPeShVmYq3t6';

	encrypt(str : string): string {
		return CryptoJS.AES.encrypt(str, this.secretKey).toString();
	}

	decrypt(str : string): string {
		return CryptoJS.AES.decrypt(str, this.secretKey).toString(CryptoJS.enc.Utf8);
	}

	setItem(key: string, value: string) {
		localStorage.setItem(key, this.encrypt(value));
	}

	getItem(key: string): string{
		const value = localStorage.getItem(key);
		return value != undefined ? this.decrypt(value) : value;
	}

	removeItem(key: string) {
		localStorage.removeItem(key);
	}

	clearAllItems() {
		localStorage.clear();
	}
}
