import { Injectable,OnInit} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { GlobalService } from 'src/app/core/adapter/service/global.service';

@Injectable({
  providedIn: 'root'
})
export class UserManagerService implements OnInit {
  IMPERSONATE_USER_URL = environment.ENV_URL +"/"+environment.UAA_SERVICE_PREFIX;
  USER_URL = environment.ENV_URL +"/"+environment.UAA_SERVICE_PREFIX+"user";
  CUSTOMER_URL = environment.ENV_URL+"/"+environment.CUSTOMER_SERVICE_PREFIX
  //USER_URL = "http://localhost:9002/"
  actionForUser = [{
    'columnName': 'UserImpersonate',
    'data': '<span style="font-size: 1.33333em; color: #63c6c3;cursor:pointer;" ><i class="fas fa-user-friends"></i><span>'
  },
  { 'columnName': 'UserDelete', 'data': '<span class="badge badge-danger" style="cursor:pointer;" >Delete</span>' }]
  constructor(private http: HttpClient,public globalService:GlobalService) { }

  private isEditUser:boolean=false;
  public isImpersonateView: any;
  public userList:any;

  ngOnInit() {
  }
  
	public getIsEditUser(): boolean {
		return this.isEditUser;
	}
	public setIsEditUser(value: boolean) {
		this.isEditUser = value;
	}
  getUserList() {
    return this.http.get(this.USER_URL+"/all");
    //return this.http.get(this.USER_URL+"user_list");
  }

  getUserMetaData(){
    return this.http.get(this.USER_URL+"/manager/table/definition?tables=user")
  }

  getCompanyList() {
    return this.http.get(this.USER_URL+"/company/all")
    //return this.companyList;
  }

  getRoleList() { 
    return this.http.get(this.USER_URL+"/role/all")
    //return this.roleList;
  }
  getImpersonateUser(email) {
    return this.http.get(this.IMPERSONATE_USER_URL+"impersonate?email=" + email)
  }

  getUserByEmail(email){
    return this.http.get(this.USER_URL+"/by_email?email="+email);
  }

  saveOrUpdateUser(userDetails){
    return this.http.post(this.USER_URL,userDetails)
  }

  deleteUserByEmail(email){
    return this.http.delete(this.USER_URL+"?email="+email)
  }

  updateProfile(userData){
    return this.http.post(this.USER_URL+"/profile",userData)
  }
  findAllCompany(){
    return this.http.get(this.CUSTOMER_URL+"all")
  }

  findAllInactiveUsers(){
    return this.http.get(this.USER_URL+"/okta/inactive_user")
  }

  sendEmailsToNonActiveUsers(emails,names){
    return this.http.post(this.USER_URL+"/okta/send_emails", {"emails":emails, "names":names});
  }

  oktaResetPassword(email){
    return this.http.get(this.USER_URL+"/okta?email="+email);
  }

  getOktaUserByEmail(email){
    return this.http.get(this.USER_URL+"/okta?email="+email);
  }

  getFactorIdByOktaId(userId){
    return this.http.get(this.USER_URL+"/okta/factors?userId="+userId);
  }

  oktaSmsEnroll(userDetails){
    return this.http.post(this.USER_URL+"/okta/sms_enroll",userDetails);
  }
  
  oktaSmsVerify(userDetails){
    return this.http.post(this.USER_URL+"/okta/sms_verify",userDetails);
  }

  getUserProfile(email){
    return this.http.get(this.USER_URL+"/profile?email="+email);
  }

  oktaSmsUnenroll(oktaId, factorId){
    return this.http.get(this.USER_URL+"/okta/sms_unenroll?oktaId="+oktaId+"&factorId="+factorId);
  }

  oktaSmsResend(userDetails){
    return this.http.post(this.USER_URL+"/okta/sms_resend",userDetails);
  }

  oktaResetPasswordByAdmin(email){
    return this.http.get(this.USER_URL+"/okta/reset_password?email="+email);
  }

  uploadMigrateUsers(formatData){
    return this.http.post(this.USER_URL+"/upload/migrate_users",formatData);
  }

  getAllShipTo(id){
    return this.http.get(this.USER_URL+"/list_ship_to?companyId="+id);
  }
}
