import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

if (environment.ENABLE_GOOGLE_ANALYTICS) {
  document.write(
    `
    <script async src='https://www.googletagmanager.com/gtag/js?id=UA-116476339-2'></script>
    <script>
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'UA-116476339-2');
    </script>    
    `
  );
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
