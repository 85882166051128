import { GlobalService } from './global.service';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse, HttpHeaders, HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError, BehaviorSubject } from 'rxjs';
import { finalize, catchError, filter, take, switchMap, tap, map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { TemplateService } from './template.service';
import { StorageService } from '../../service/storage.service';

@Injectable({
    providedIn: 'root',
})
export class AuthInterceptor implements HttpInterceptor {
    private isRefreshing = false;
    private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

    constructor(public globalService: GlobalService,
                private http: HttpClient, 
                private router: Router, 
                private templateService:TemplateService,
                private storageService: StorageService) { }

    intercept(req: HttpRequest<any>,
        next: HttpHandler): Observable<HttpEvent<any>> {
        const idToken = this.storageService.getItem('userToken');
        if (idToken) { //Api call with token
            const cloned = this.addToken(req, idToken);
            return next.handle(cloned)
                .pipe(
                    // map((event: HttpEvent<any>) => {
                    //     if (event instanceof HttpResponse && event.body && event.body.hasError) {
                    //         if (event.body.statusCode == 401) {
                    //             this.handle401Error(cloned, next).subscribe(data => {return data;});
                    //         } else {
                    //             return event;
                    //         }
                    //     } else {
                    //         return event;
                    //     }
                    // }),
                    catchError((error: HttpErrorResponse) => {
                        let errorMessage = '';
                        if (error instanceof HttpErrorResponse && error.status === 401) {
                            console.log('Session expired');
                            return this.handle401Error(cloned, next);
                          } 
                        if (error.error instanceof ErrorEvent) {
                            // client-side error
                            errorMessage = `Error at client: ${error.error.message}`;
                        } else {
                            // server-side error
                            //errorMessage = `Internal Server Error: Please try again in a minute`;
                            errorMessage = error.error.message;
                        }
                        this.globalService.addAlert('danger', errorMessage);
                        this.globalService.hideLoader();
                        return throwError(error);
                    }
                    ));
        } else {  //Api call without token
            return next.handle(req)
                .pipe(catchError((error: HttpErrorResponse) => {
                    if(error.status == 401){
                        //window.location.href = "#";
                        return throwError(error);
                    }else{
                        let errorMessage = '';
                        if (error.error instanceof ErrorEvent) {
                            // client-side error
                            errorMessage = `Error at client: ${error.error.message}`;
                        } else {
                            // server-side error
                            //errorMessage = `Internal Server Error: Please try again in a minute`;
                            errorMessage = error.error.message;
                        }
                        this.globalService.addAlert('danger', errorMessage);
                        return throwError(error);
                    }
                }
                ), finalize(() => this.globalService.hideLoader()));
        }
    }

    private addToken(request: HttpRequest<any>, token: string) {
        if(request.url.includes('token/refresh')) {
            token = this.storageService.getItem('refreshToken');
            return request.clone({
                setHeaders: {
                    'Authorization': `Refresh ${token}`
                }
            });
        }
        return request.clone({
            setHeaders: {
                'Authorization': `Bearer ${token}`
            }
        });
    }

    private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
        if (!this.isRefreshing) {
            this.isRefreshing = true;
            this.refreshTokenSubject.next(null);
            
            return this.refreshToken().pipe(
                switchMap((result: any) => {
                    if (result.hasError) {
                        window.location.href = "#";//redirect to login page
                        this.storageService.clearAllItems();
                        this.globalService.addAlert('danger', "Session is expired, please login again.");
                    }else{
                        this.storageService.setItem('userToken', result.token);
                        this.storageService.setItem('refreshToken', result.refreshToken); 
                        this.isRefreshing = false;
                        this.refreshTokenSubject.next(result.token);
                        console.log('retrying ', request.url);
                        return next.handle(this.addToken(request, result.token));
                    }
                }));
        } else {
            return this.refreshTokenSubject.pipe(
                filter(token => token != null),
                take(1),
                switchMap(token => {
                    console.log('retrying ', request.url);
                    return next.handle(this.addToken(request, token));
                }));
        }
    }

    refreshToken() {
        return this.http.get(this.globalService.apiRoot + 'token/refresh');
    }
}
