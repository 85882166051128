import { GlobalService } from '../../../adapter/service/global.service';

import { Component, OnInit, Input, Output, EventEmitter, HostListener, AfterViewInit, ChangeDetectorRef, ViewEncapsulation, ViewChild, ElementRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { from, fromEvent } from 'rxjs';
import { map, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Router } from '@angular/router';
import config from 'config';
import { UserManagerService } from 'src/app/application/user-manager/user.manager.service';
import { StorageService } from 'src/app/core/service/storage.service';


@Component({
  selector: 'app-custom-dataset',
  templateUrl: './dataset.component.html',
  styleUrls: ['./dataset.component.scss', '../../../../app.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DatasetComponent implements OnInit, AfterViewInit {

  public rows: Array<any> = [];

  @Input('columns')
  public columnsOfData: Array<any> = [];
  @Input()
  public extraColumns: Array<any> = [];
  @Output() valueChange = new EventEmitter();
  @Output()
  processRowData: EventEmitter<any> = new EventEmitter<any>();


  public columns: Array<any> = [];
  public page: number = 1;
  public sortBy: string = '';
  public orderBy: string = '';
  public itemsPerPage: number = 20;
  public maxSize: number = 10;
  public numPages: number = 1;
  public length: number = 0;
  public seeMoreData: any = [];
  public seeMoreProperties: any = [];
  public mediaChanged: boolean = true;
  public pageStart: number = 0;
  public pageEnd: number = 0;
  public orderNo: any;
  public result: any;
  public isConfigChange: boolean = true;
  public columnMap = new Map();
  public dataMap = new Map();
  public showAddUser: boolean = false;
  public sortingConfig: any;
  public searchText: string = '';
  public width;
  @Input('link')
  public paginationLink: any;

  @Input('request_params')
  public requestParams: any;

  private data: Array<any> = [];
  @Input('data')
  public row_data: any = [];
  
  @Input('searchKeyword')
  public searchKeyword = "";

  @Input()
  public showFilter: boolean = false;
  @Input()
  public showPagination: boolean = true;

  @Input("page")
  public pageData: any;

  @ViewChild('searchUser') searchInput: ElementRef;
  
  @Input("total_elements")
  public totalElements: any;
  public config: any;
  public constructor(public router: Router, 
                     public userManagerService: UserManagerService, 
                     private changeDetector: ChangeDetectorRef, 
                     private http: HttpClient, 
                     public globalService: GlobalService,
                     private storageService: StorageService) {
  }

  public ngOnInit() {
    if ((this.router.url) === "/home/user-manager/user-list") {
      this.showAddUser = true;
    }
    fromEvent(document.getElementById('searchUser'), 'input').pipe(
      // get value
      map((event: any) => {
        return event.target.value;
      })
      // Time in milliseconds between key events
      , debounceTime(800)
      // If previous query is diffent from current   
      , distinctUntilChanged()
      // subscription for response
    ).subscribe((searchData: string) => {
      this.searchText = searchData;
      this.searchFilter()
    })
    this.width = window.innerWidth;
    this.renderTable(this.row_data, this.columnsOfData, window.innerWidth, this.page);
  }

  public ngOnChanges(changes: any) {
    this.renderTable(this.row_data, this.columnsOfData, window.innerWidth, this.page);
  }
  public ngAfterViewInit(): void {
  }

  ngAfterViewChecked() { this.changeDetector.detectChanges(); } 

  public returnClicked(someData: any) {
    if(someData.columnClicked ==="delete" && someData.data.name){
      this.searchInput.nativeElement.value = '';
      this.page=1;
      if(this.sortingConfig != undefined){
        this.sortingConfig.sorting.columns.forEach(element => {
          if(element.sort==="desc"){
            element.sort = "asc";
          }
        });
      }
    }
    this.valueChange.emit(someData);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if (this.width != event.target.screen.width) {
      this.mediaChanged = !this.mediaChanged;
      this.width = event.target.screen.width;
      this.renderTable(this.data, this.columnsOfData, event.target.screen.width, this.page);
    }
  }

  protected getNumberOfColumnsToDisplay(width: any) {
    let returnCol = 10;
    switch (true) {
      case (width < 330):
        returnCol = 2;
        break;
      case (width < 380):
        returnCol = 3;
        break;
      case (width < 420):
        returnCol = 4;
        break;
      case (width < 780):
        returnCol = 7;
        break;
      default:
        returnCol = 100;
        break;
    }
    return returnCol;
  }

  protected toDisplay(fromDisplay: string, width: any) {
    let toAdd = false;
    switch (true) {
      case (width < 600):
        break;
      case (width < 1250):
        if (fromDisplay == 'tablet')
          toAdd = true;
        break;
      case (width < 1520):
        if (fromDisplay == 'tablet' || fromDisplay == 'desktop')
          toAdd = true;
        break;
      case (width > 1521):
        if (fromDisplay == 'tablet' || fromDisplay == 'xl' || fromDisplay == 'desktop')
          toAdd = true;
        break;
    }

    if (fromDisplay == 'always')
      toAdd = true;
    return toAdd;
  }

  //This method is used ro construct data to render the table
  public renderTable(row_data: any, columns: any, screenWidth: any, pageNumber) {
    if (typeof row_data != "undefined")
      for (let b = 0; b < row_data.length; b++) {
        let hasExpanded = row_data[b].hasOwnProperty('expanded')
        if (hasExpanded) row_data.splice(b, 1);
      }


    this.data = [];
    this.columns = [];
    let contentsHidden = false;
    let columnToDispaly = this.getNumberOfColumnsToDisplay(screenWidth);
    if (columns != undefined) {
      let columnExist = columns.some(columns => columns['name'] === 'extraRowData')
      if (!columnExist) {
        columns.push({ title: '', name: 'extraRowData', sort: false, displayFormFactor: "never" });
      }
      for (let i = 0; i < columns.length; i++) {
        this.columnMap.set(columns[i].fieldname, columns[i].headingText);
        if (typeof columns[i].displayFormFactor !== "undefined") {
          if (this.toDisplay(columns[i].displayFormFactor, screenWidth))
            this.columns.push({ title: columns[i].headingText, name: columns[i].fieldname, sort: (columns[i].sortable) });
          else if (columns[i].displayFormFactor != 'never')
            contentsHidden = true;
        } else if (typeof columns[i].headingText !== "undefined") {
          if (this.columns.length - this.extraColumns.length < columnToDispaly)
            this.columns.push({ title: columns[i].headingText, name: columns[i].fieldname, sort: columns[i].sortable });
          else
            contentsHidden = true;
        } else if (typeof columns[i].title !== "undefined") {
          if (this.columns.length - this.extraColumns.length < columnToDispaly)
            this.columns.push({ title: columns[i].title, name: columns[i].fieldname, sort: columns[i].sort });
          else
            contentsHidden = true;
        }
      }
    }
    if (row_data != undefined) {
      for (let i = 0; i < row_data.length; i++) {
        this.data[i] = {};
        for (let j = 0; j < this.columns.length; j++) {
          /*  if (this.columns[j].name === "sales_order_number") {
            this.data[i][this.columns[j].name] = "<div class='btn btn-sm btn-link '  style='color: #63c6c3 !important;text-decoration: none !important;background-color: transparent !important;'>" + this.row_data[i][this.columns[j].name] + "</div>";
          } else { */
          if (this.row_data[i] != undefined)
            this.data[i][this.columns[j].name] = this.row_data[i][this.columns[j].name];
          //}
        }
      }
    }

    if (this.extraColumns.length != 0 && columns != undefined) {
      for (let i = 0; i < this.extraColumns.length; i++) {
        this.columns.push({ title: '', name: this.extraColumns[i].columnName, sort: false })
        for (let j = 0; j < this.data.length; j++) {
          if (this.extraColumns[i].columnName === 'UserDelete') {
            let userName = this.getName(this.data[j].name);
            this.data[j][this.extraColumns[i].columnName] = '<a class="row-btn delete" style="cursor:pointer;" data-toggle="tooltip" data-placement="bottom" title="Remove ' + userName + '">Delete</a>'
          } else if (this.extraColumns[i].columnName === 'UserImpersonate') {
            let userName = this.getName(this.data[j].name);
            this.data[j][this.extraColumns[i].columnName] = '<a class="link" data-toggle="tooltip" data-placement="bottom" title="Impersonate as ' + userName + '"><i class="fas fa-user-friends fa-lg"></i></a>'
          } else {
            this.data[j][this.extraColumns[i].columnName] = this.extraColumns[i].data;
          }
        }
      }
    }
    if (contentsHidden) {
      this.columns.unshift({ title: '', name: 'viewMoreColumns', sort: false });
      for (let j = 0; j < this.data.length; j++) {
        this.data[j]['viewMoreColumns'] = "<div class='btn btn-sm px-3'><i class='fas fa-plus fa-1x'></i></div>";
        this.data[j]['extraRowData'] = this.row_data[j];
      }
    }
    if (this.sortingConfig != undefined) {
      for (let i = 0; i < this.columns.length; i++) {
        if (this.columns[i].name === this.sortingConfig.sorting.columns[0].name) {
          this.columns[i].sort = this.sortingConfig.sorting.columns[0].sort;
        }
      }
    } else {
      for (let i = 0; i < this.columns.length; i++) {
        this.columns[i].sort = this.columns[i].sort ? "asc" : this.columns[i].sort;
      }
    }
    // if (!this.isConfigChange) {
    this.config = {
      paging: true,
      sorting: { columns: this.columns },
      filtering: { filterString: '' },
      className: ['table-striped', 'table-bordered']
    };
    //this.isConfigChange = false;
    // }
    this.length = this.totalElements;
    if (this.pageData != undefined) {
      this.itemsPerPage = this.pageData.itemsPerPage;
      this.page = this.pageData.pageNumber + 1;
      pageNumber = this.page;
      setTimeout(() => {
        this.page = this.pageData.pageNumber + 1;
      }, 50)
    }

    this.rows = this.page && this.config.paging ? this.customChangePage(pageNumber, this.data) : this.data;
    this.length = this.totalElements;
    // this.onChangeTableOnApiCall(this.config, pageNumber);
  }

  getName(data): string {
    return data != undefined ? (data).replace(/<[^>]*>/g, '') : "";
  }
  public onChangeTableOnApiCall(config: any, pageNumber): any {
    if (config.filtering) {
      Object.assign(this.config.filtering, config.filtering);
    }

    if (config.sorting) {
      Object.assign(this.config.sorting, config.sorting);
    }
    //let filteredData = this.changeFilter(this.data, this.config);
    this.sortingConfig = config;
    this.customSort(this.config, pageNumber);
    this.changeDetector.detectChanges();
  }

  //This method used to display number of elements in page
  public customChangePage(page: any, data: Array<any> = this.data): Array<any> {
    let start = (page - 1) * this.itemsPerPage;
    let end = this.totalElements
    if (this.itemsPerPage < this.totalElements) {
      end = this.itemsPerPage * page
      if (end > this.totalElements) {
        end = this.totalElements;
      }
    }
    this.page = page;
    this.pageStart = start;
    this.pageEnd = end
    return data;
  }

  //This method used to call on items per change
  public onPerpage(page: any = { page: this.page, itemsPerPage: this.itemsPerPage }) {

    this.globalService.showLoader();
    let pageNumber, selectedPage;
    if (page.page === undefined) {
      pageNumber = 0
      selectedPage = 1;
    } else {
      selectedPage = page.page;
      pageNumber = page.page - 1;
    }
    this.apiCall(pageNumber).subscribe((val) => {
      this.globalService.hideLoader()
      this.processRowData.emit(val);
      this.renderTable(this.row_data, this.columnsOfData, window.innerWidth, selectedPage);
    });
  }



  //This method is used to call the pagination api
  public apiCall(pageNo): any {
    let url = "pageNo=" + pageNo + "&pageSize=" + this.itemsPerPage;

    // based on sort by and order by append the url
    let sortUrl = url.concat(this.sortBy === '' || this.orderBy === '' ? '' : "&sortBy=" + this.sortBy
      + "&orderBy=" + this.orderBy);

    //  based on search filter append the url  
    let search_text = this.searchText == ""? this.searchKeyword:this.searchText;
    
    let searchUrl = this.seeMoreData === '' ? sortUrl : sortUrl + "&searchText=" + search_text;

    let apiUrl = environment.ENV_URL + environment.FORWARD_SLASH + this.paginationLink;
    if (this.paginationLink != undefined && (this.paginationLink).includes('?')) {
      apiUrl = apiUrl + "&" + searchUrl;
    } else {
      apiUrl = apiUrl + "?" + searchUrl;
    }
    return this.http.get(apiUrl)
  }

  public searchFilter() {
    this.onPerpage({})
  }

  public customSort(config, page) {
    let sortBy, orderBy, check = true;
    config.sorting.columns.forEach(function (value, key) {
      if (check && (value.sort === "asc" || value.sort === "desc" || value === true)) {
        sortBy = value.name;
        orderBy = value.sort
        check = false
      }
    })
    if (sortBy !== undefined || orderBy !== undefined) {
      let pageObj = { page: page, itemsPerPage: this.itemsPerPage }
      this.orderBy = orderBy;
      this.sortBy = sortBy;
      this.onPerpage(pageObj);
    }
  }


  public showMoreOfRow(data: any) {
    let propertiesOfRow = Object.getOwnPropertyNames(data.row);
    var index = propertiesOfRow.indexOf("extraRowData");
    if (index !== -1) propertiesOfRow.splice(index, 1);
    let rowData = data.row;
    let matchedData = {};

    for (let i = 0; i < this.row_data.length; i++) {
      let matchFound = false;
      for (let j = 0; j < propertiesOfRow.length - this.extraColumns.length - 1; j++) {
        if (this.row_data[i][propertiesOfRow[j]] != this.orderNo) {
          if (rowData[propertiesOfRow[j]] != this.row_data[i][propertiesOfRow[j]]) {
            break;
          } else if (j == propertiesOfRow.length - this.extraColumns.length - 2) {
            matchFound = true;
          }
        }
      }
      if (matchFound) {
        matchedData = JSON.parse(JSON.stringify(this.row_data[i]));
        break;
      }
    }
    for (let i = 0; i < propertiesOfRow.length; i++)
      delete matchedData[propertiesOfRow[i]];
    let rowIndex = -1;

    for (let i = 0; i < this.rows.length; i++) {
      if (JSON.stringify(this.rows[i]) == JSON.stringify(rowData)) {
        rowIndex = i;
        break;
      }
    }


    if (data.row.viewMoreColumns.indexOf('fa-plus') > 1) {
      data.row.viewMoreColumns = "<div class='btn btn-sm px-3'><i class='fas fa-minus fa-1x'></i></div>";
      let obj = {}
      let str = '';
      let seeMoreProperties = Object.getOwnPropertyNames(matchedData);
      for (let i = 0; i < seeMoreProperties.length; i++) {
        if(this.getColumnName(seeMoreProperties[i]) == "Email"){
          str += '<span class="text-left float-left font-weight-bold"> ' + this.getColumnName(seeMoreProperties[i]) + ' : &nbsp;</span><span class=" text-left float-left" style="word-break:break-word"> ' + matchedData[seeMoreProperties[i]] + ' </span><span class="clearfixs"></span>';
        }else{
          if(typeof matchedData[seeMoreProperties[i]] !== "object")
            str += '<span class="text-left float-left font-weight-bold"> ' + this.getColumnName(seeMoreProperties[i]) + ' : &nbsp;</span><span class=" text-left float-left"> ' + matchedData[seeMoreProperties[i]] + ' </span><span class="clearfixs"></span>';
        }
        //str += '<span class="text-left"><span class="float-left font-weight-bold">' + this.getColumnName(seeMoreProperties[i]) + ' : </span><span class="float-left">' + matchedData[seeMoreProperties[i]] + '</span></span><br/>'; 
      }
      // str += '</div>';
      let extraRowData = rowData.extraRowData;
      this.columns.push({ title: '', name: 'extraRowData', sort: false, displayFormFactor: "never" });
      this.columns.push({ title: '', name: 'expanded', sort: false, displayFormFactor: "never" });
      for (let i = 0; i < this.columns.length; i++) {
        if (this.columns[i].name == "extraRowData") {
          obj[this.columns[i].name] = extraRowData;
        }
        else if (this.columns[i].name == "expanded") {
          obj[this.columns[i].name] = true;
        }
        else if (this.columns[i].name != propertiesOfRow[0])
          obj[this.columns[i].name] = '';
        else
          obj[this.columns[i].name] = str;
      }
      this.rows.splice(rowIndex + 1, 0, obj);
    } else {
      this.rows.splice(rowIndex + 1, 1);
      data.row.viewMoreColumns = "<div class='btn btn-sm px-3'><i class='fas fa-plus fa-1x'></i></div>";
    }
  }

  public getColumnName(fieldName) {
    return this.columnMap.has(fieldName) ? this.columnMap.get(fieldName) : fieldName;
  }

  public onCellClick(data): any {
    // data.event.stopPropagation()
    this.orderNo = (new DOMParser().parseFromString(data.row.sales_order_number, "text/xml")).documentElement.textContent;
    let internalData = typeof data.row[data.column] != "undefined" ? data.row[data.column] : data.row.extraRowData[data.column]
    if (data.column == 'viewMoreColumns') {
      this.showMoreOfRow(data);
    }
    else if (internalData.toString().indexOf('btn btn-sm btn-link') > 1) {
      let value = internalData.substring(internalData.lastIndexOf("'>") + 2, internalData.lastIndexOf("</"));
      this.returnClicked(value);
    } else if (internalData.toString().indexOf('cursor: pointer') > 1) {
      let value = (new DOMParser().parseFromString(internalData, "text/xml")).documentElement.textContent;
      this.returnClicked(value);
    } else {
      this.returnClicked({ 'columnClicked': data.column, 'data': data.row });
      // for (let i = 0; i < this.extraColumns.length; i++) {
      //   if (data.column == this.extraColumns[i].columnName)
      //     this.returnClicked({ 'columnClicked': data.column, 'data': data.row });
      // }
    }
  }

  navigate() {
    this.globalService.isProfileEditor = false;
    this.userManagerService.setIsEditUser(false);
    this.storageService.setItem("isEditUser", 'false');
    this.storageService.setItem("isProfileEditor", 'false');
    this.router.navigate(['/home/user-manager/user'])
  }

}




