// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  ENV_URL: 'https://api.dev.corsicanamattress.com',
  FORWARD_SLASH: '/',
  ENABLE_GOOGLE_ANALYTICS: false,

  //azure maps
  AZURE_PRIMARY_KEY:'nmggRj3Sh6CPixahV7ja_1U_cHb3z9zI4f_cDl4XkH8',
  MAX_PROGRESS_TIMER: 60,
  WEATHER_REFRESH: 300000,
  DASHBOARD_REFRESH: 60000,

  // Okta
  OKTA_ISSUER: 'https://secure.gocorsicana.com/oauth2/default',
  OKTA_REDIRECT_URL: 'http://localhost:4200/implicit/callback',
  OKTA_CLIENT_ID: '0oat66e8a61qMDAG3356',
  OKTA_PKCE: true,
 
  // UAA Service
  UAA_SERVICE_PREFIX: 'uaa/',
  UAA_TOKEN: 'token',
  USER_SERVICE_URL: 'user_list',
  APPLICATION_OKTA_BOOLEAN: "okta/status",

  // CUSTOMER SERVICE
  CUSTOMER_SERVICE_PREFIX: 'customer/',
  CUSTOMER_SEARCH_TYPE: 'lov?types=searchType',
  CUSTOMER_SEARCH: 'search',

  //ORDER TRACKER
  ORDER_SERVICE_PREFIX: 'order/',
  ORDER_SEARCH_TYPE: 'lov?types=searchType&types=filterType',
  ORDER_METADATA: 'table/definition?tables=order',
  ORDER_LIST: 'list?',
  ORDER_EXPORT_PAGE_SIZE: 10000,

  // CATALOG SERVIE
  CATALOG_SERVICE_PREFIX: 'catalog/',
  CATALOG_CUST_ID: 'cust_id',
  PRODUCT_SEARCH: 'product/search',
  PRODUCT_ADD: 'product/add',
  PRODUCT_DELETE: 'product/delete',

  // ONBOARD SERVIE
  ONBOARD_SERVICE_PREFIX: '/onboard/',
  ONBOARD_PAGINATION_PREFIX: 'onboard/',
  ONBOARD_INBOX_METADATA: 'application/table/definition?tables=inbox&tables=watchList',
  ONBOARD_INBOX_DATA: 'application/inbox',
  ONBOARD_WATCHLIST_DATA: 'application/watchlist',
  ONBOARD_CLOSED_METADATA: 'application/table/definition?tables=closed',
  ONBOARD_CLOSED_DATA: 'application/closed',
  ONBOARD_CREATE_APPLICATION: 'application/',
  ONBOARD_CREATE_SHIPTO: 'application/shipto',
  ONBOARD_GET_APPLICATION: 'application',
  ONBOARD_PROMOTION_STATUS: '/promotion/status',
  ONBOARD_ADDRESS: 'address',
  ONBOARD_REVIEW: 'review',
  ONBOARD_WORKFLOW: 'workflow',
  LOGISTIC_PULLDOWN: 'logisticpulldown',
  ASSIGN_LOGISTIC: 'assignlogistic',
  ASSIGN_AR: 'assignar',
  ASSIGN_ADMIN: 'assignadmin',
  UNASSIGN_AR:'unassignar',
  SOFT_DELETE: 'softDelee',
  HARD_DELETE: 'hardDelee',
  ADD_OHMID: 'ohmid',
  DELETE_FILE: 'deletefiles',
  ONBOARD_VERIFY_ADDRESS: '/address/verify',
  ONBOARD_ACCOUNT_CONTACT: 'application/account_contact',
  ONBOARD_GROUP_TIER: 'application/group_tier',
  ONBOARD_GET_SHIPPING_FOR_SHIPTO:'application/ship_to',
  ONBOARD_SAVE_SHIPTO_ID:'application/ship_to_id',

  // SHARED
  APPLICATION_SHARED: '/shared',
  APPLICATION_PULLDOWN: '/pulldown',

  // SHARED SERVICE
  SHARED_SERVICE_PREFIX: '/shared',
  POWERBI_REPORT_CONFIG: '/powerbi/config',
  REPORT_ID_EQUALS: '?reportId=',
  POWERBI_ALL_REPORTS: '/powerbi/reports',
  FORMS: '/forms',
  FORM_DOWNLOAD: '/document/download',
  TRAINING_VIDEOS: '/video',

  //CHECK IN
  CHECK_IN_PREFIX: 'admin/checkin/',
  CHECK_IN_SALES_PERSON: 'list/salesperson',
  CHECK_IN_INCOMING_MESSAGE: 'list/incomingmessage',
  CHECK_IN_SEND_MESSAGE: 'send/sms',

  //ADMIN
  ADMIN_PREFIX: 'admin/',

  // ALERT
  ALERT_PREFIX: 'admin/alert',
  ALERT_FILE_UPLOAD: '/upload',

  //AUDIT
  AUDIT_PREFIX: 'admin/audit/',
  AUDIT_METADATA: 'table/definition?tables=',
  AUDIT_FIND_ALL: 'all?auditType="',
  
  //EXTERNAL API
  API_CREATE_TOKEN: 'external/token',
  API_VIEW_TOKEN: 'external/token/validate',
  API_LINK_TOKEN: 'generate/linktoken',

  //DYNAMIC PAGE
  PAGE_TABLE_DATA: "pagetemplate/all",
  TABLE_METADATA: 'table/definition?tables=',
  PAGE: 'pagetemplate',
  COLUMN_TABLE_DATA: 'pagetemplate/column/all?pageId=',
  FIELD_TABLE_DATA: 'pagetemplate/column/field/all?pageId=',
  RULE_TABLE_DATA: 'pagetemplate/column/field/rule/all?pageId=',
  CONDITION_TABLE_DATA: 'pagetemplate/column/field/rule/condition/all?pageId=',

  //DYNAMIC WORKFLOW
  WORKFLOW_TABLE_DATA: "workflow_action/all",
  WORKFLOW_EMAIL_TABLE_DATA: "workflow_email/all",
  WORKFLOW_ACTION:"workflow_action",
  WORKFLOW_EMAIL:"workflow_email",

  //NightsBridge Dealer
  DEALER_SERVICE_PREFIX: "dealer"
 
 };


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
