import { Injectable } from '@angular/core';
import * as OktaSignIn from '@okta/okta-signin-widget';

@Injectable({
    providedIn: 'root',
})
export class Okta {
  widget;
  clientId = '0oat66e8a61qMDAG3356';
  orgUrl = 'https://secure.gocorsicana.com';
  redirectUri = window.location.protocol + '//' + window.location.hostname;

  constructor() {
    this.widget = new OktaSignIn({
        baseUrl: this.orgUrl,
        clientId: this.clientId,
        language: 'en',
        authParams: {
          scopes: ['openid', 'email'],
          pkce: false
        },
        features: {
          helplink: false,
          multiOptionalFactorEnroll: true,
          registration: false,
          rememberMe: true,
          securityImage: false,
          smsRecovery: true,
          idpDiscovery: true,
          autoPush: true
        },
        idpDiscovery: {
          requestContext: this.redirectUri + '/login_with_redirect'
        },
        helpLinks: {
          help: this.orgUrl + '/help/login',
          forgotPassword: this.orgUrl + '/signin/forgot-password?fromURI=' + this.redirectUri + '/login&clientId=' + this.clientId
        },
      });
  }

  getWidget() {
    return this.widget;
  }
}
