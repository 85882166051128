import { GlobalService } from './global.service';
import { Injectable } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { HttpClient } from '@angular/common/http';
import { Menu } from '../../view/page-template/new-header/modal/menu';


@Injectable({
  providedIn: 'root'
})
export class TemplateService {

  adminMenu: Menu[] = [];
  displayMenu: Menu[] = [];
  permissionList: Menu[] = [];
  sideNavIsOpen = false;
  isCollapsed = false;
  selectedRole: string;
  url = '';
  firstName: string;
  userEmail: string;
  lastName: string;


  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private http: HttpClient,
    public globalService: GlobalService) {}

  toggleMenu() {
    this.sideNavIsOpen = !this.sideNavIsOpen;
  }

  refreshTheView() {
    this.selectedRole = 'Admin';
    library.add(fas);
  }
}
