import { Component, OnInit, NgZone } from '@angular/core';
import * as OktaSignIn from '@okta/okta-signin-widget';
import { HttpClient } from '@angular/common/http';
import { Okta } from '../../adapter/model/okta';
import { OktaAuthService } from '@okta/okta-angular';
import { AuthService } from '../../adapter/service/auth.service';
import { Router } from '@angular/router';
import { TemplateService } from '../../adapter/service/template.service';
import { GlobalService } from '../../adapter/service/global.service';
import { environment } from 'src/environments/environment';
import { StorageService } from '../../service/storage.service';

@Component({
  selector: 'app-login-with-redirect',
  templateUrl: './login.with.redirect.component.html',
  styleUrls: ['./login.with.redirect.component.scss']
})
export class LoginWithRedirectComponent implements OnInit {

  oktaSignIn: OktaSignIn;
  redirectUri = window.location.protocol + '//' + window.location.hostname;
  TOKEN_API = environment.ENV_URL +"/"+environment.UAA_SERVICE_PREFIX + environment.UAA_TOKEN;

  constructor(private http: HttpClient, 
              public okta: Okta, 
              public oktaAuth: OktaAuthService,
              public authService: AuthService, 
              public router: Router, 
              private templateService: TemplateService,
              public globalService: GlobalService, 
              private ngZone: NgZone, 
              private storageService: StorageService) 
  {
    this.oktaSignIn = this.okta.getWidget();
  }


  ngOnInit() {
    this.globalService.showLoader();
    this.oktaSignIn.authClient.session.get().then((res) => {
      if (res.status === 'ACTIVE') {
        this.http.post(this.TOKEN_API,
            {
              type: 'sessionId',
              oktaToken: res.id
            })
            .subscribe(
              (val:any) => {
                if(val.hasError) {
                  this.globalService.addAlert('danger',val.message);
                  this.ngZone.run(() => this.router.navigate(['/login'])).then();
                }else{
                  this.login(val);
                }
              },
              (error:any) => {
                // console.log("here");
                this.ngZone.run(() => this.router.navigate(['/login'])).then();
              });
          } else {
            this.globalService.hideLoader();
            this.authService.logout();
            this.ngZone.run(() => this.router.navigate(['/login'])).then();
          }
      });
  }

  login(res) {
    this.globalService.hideLoader();
    this.authService.login(res);
    this.storageService.removeItem("userDetails");
    this.storageService.removeItem("logoUrl");
    this.storageService.removeItem("userList");
    this.templateService.refreshTheView();
    this.ngZone.run(() => this.router.navigate(['/home'])).then();
  }
}
