import { Component, OnInit } from '@angular/core';
import { GlobalService } from 'src/app/core/adapter/service/global.service';
import { OrderTrackerService } from 'src/app/application/order-tracker/order.tracker.service';
import { StopDTO } from 'src/app/application/order-tracker/model/order.tracker';
import { environment } from 'src/environments/environment';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from '../api.service';

@Component({
  selector: 'app-order-details',
  templateUrl: './order-details.component.html',
  styleUrls: ['./order-details.component.scss']
})
export class OrderDetailsComponent implements OnInit {

  year: any;
  stopList: any[];
  roleListForStops: Array<String> = ["Sales Manager", "Sales", "Customer CSR", "Franchisee"];
  isStopNotPresent: boolean = false;
  errMessage: any = "";
  isStopPresent: boolean = false;
  eta: any = "-";
  isETA: boolean = false;
  showTable: boolean = false;
  hideShipping: boolean = false;
  orderItemColumn: Array<any> = [];
  orderTotalElements: any;
  orderItemData: any;
  orderItemPaginationLink: any;
  carrierName: string;
  orderDetailHeader: string = "";
  totalPieces: any;
  displayOrderTotal: boolean;
  netTotal: any;
  orderTotal: any;
  PAGINATION_LINK: string = environment.ORDER_SERVICE_PREFIX
  token: string;
  salesOrderNumber: string = '';
  showTabless: boolean = false;
  isSplitOrder = false;
  childOrders = [];

  constructor(public globalService: GlobalService, private route: ActivatedRoute,
    private apiService: ApiService) { }

  ngOnInit() {
    this.token = this.route.snapshot.queryParamMap.get('link_token');
    this.verifyToken(this.token);
    this.year = new Date().getFullYear();
    this.isStopNotPresent = false;
    this.errMessage = "";
    this.isStopPresent = false;
    this.eta = "-";
    this.isETA = false;
    this.errMessage = "";
    this.showTable = false;
    this.hideShipping = false;
    this.salesOrderNumber = '';
    this.showTabless=false;
  }

  verifyToken(data) {
    this.apiService.viewToken(data).subscribe((response: any) => {
      if (!response.isExpired && response.salesOrderNumber != null) {
        this.salesOrderNumber = response.salesOrderNumber;
        this.globalService.showLoader();
        this.getStopDetails();
      } else {
        this.globalService.addAlert("danger", "something went wrong");
      }
    }, error => {
      console.log(error);
    });
  }

  getStopDetails() {
    this.apiService.getTMSOrderDetails(this.salesOrderNumber, this.token).subscribe((res: any) => {
      if (!res.hasError) {
        this.showTable = true;
        if(res.routeStatus == "Split Order"){
          this.isSplitOrder = true;
          this.childOrders = [];
          this.orderDetailHeader = "Order Split - Tracking details N/A";
          // this.apiService.getChildOrderDetails(res.salesOrderNumber).subscribe((response: any) => {
          //   response.forEach(element => {
          //     this.childOrders.push(element);
          //   });
          //   this.globalService.hideLoader();
          // });
        }else{
          this.stopList = [];
          let customerName = res.customerName;
          let customerDrop = res.customerDrop;
          let setForAllColor = res.routeStatus == "Delivered";
          let stops = res.stops;
          if (res.routeStatus == "Not Scheduled") {
            this.hideShipping = true;
          } else if( res.routeStatus == "Unallocated" ){
            this.isStopNotPresent = true;
          } else {
            this.hideShipping = false;
            if (res.routeStatus == "In Transit" || res.routeStatus == "Delivered")
              if (res.stops.length > 0) {
                this.isStopPresent = true;
                for (let i = 0; i < stops.length; i++) {
                  let stopNumber = stops[i].stop_number;
                  let isStopDelivered = stops[i].arrived.length > 0 || setForAllColor;
                  if (!isStopDelivered) {
                    for (let j = i; j < stops.length; j++) {
                      isStopDelivered = stops[j].arrived.length > 0;
                      if (isStopDelivered) break;
                    }
                  }
                  let plannedArrival = stops[i].planned_arrival;
                  let arrived = stops[i].arrived;
                  let address = ((customerDrop === stops[i].stop_number || !this.roleListForStops.includes("Customer CSR") ? stops[i].address : " ") + " " + stops[i].city + ", " + stops[i].state);
                  let custName = ((customerDrop === stops[i].stop_number) ? customerName : ((stops[i].customer_name === customerName || !this.roleListForStops.includes("Customer CSR")) ? stops[i].customer_name : " "));
                  this.stopList.push(new StopDTO(stopNumber, isStopDelivered, plannedArrival, arrived, address, custName));
                  if (this.roleListForStops.includes("Customer CSR") && customerDrop === stops[i].stop_number) {
                    break;
                  }
                }
              } else {
                this.isStopNotPresent = true;
              }
            else {
              this.isETA = true;
              this.eta = res.eta.length > 0 ? res.eta : '-';
            }
          }
          this.orderItemDetails(res.routeStatus);
        }
      } else {
        this.showTable = false;
        this.errMessage = res.message;
        this.globalService.hideLoader();
      }
    }, err => {
      console.log(err);
      this.globalService.hideLoader();
    });
  }

  orderItemDetails(status) {
    this.apiService.getOrderDetail(this.salesOrderNumber, status, this.token).subscribe((response: any) => {
      this.orderItemProcessData(response, status);
      this.globalService.hideLoader()
    }, err => {
      console.log(err);
      this.globalService.hideLoader();
    })
    this.getOrderDetailMetaData();
  }

  getOrderDetailMetaData() {
    this.globalService.showLoader();
    this.apiService.getOrderDetailMetaData(this.token).subscribe((response: any) => {
      let orderItemMeta = response.v_orderItemDetail.columnDefinitions;
      let orderItemMetaDataList = [];
      for (let i = 0; i < orderItemMeta.length; i++) {
        if (orderItemMeta[i].fieldname != "price" && orderItemMeta[i].fieldname != "total") {
          orderItemMetaDataList.push(orderItemMeta[i]);
        }
      }
      this.orderItemColumn = orderItemMetaDataList;
      this.showTabless = true;
      this.globalService.hideLoader();
    }, err => {
      console.log(err);
      this.globalService.hideLoader();
    });
  }


  orderItemProcessData(response, status) {
    this.orderTotalElements = response.totalElements;
    let  orderItemsss = response.content;
    for (let i = 0; i < orderItemsss.length; i++) {
      delete orderItemsss[i].price;
      delete orderItemsss[i].total;
    }
    this.orderItemData = orderItemsss;
    this.orderItemPaginationLink = this.PAGINATION_LINK + response.link;
    let carrier_name = response.additionalData.carrierName;
    if (carrier_name == null) {
      carrier_name = "-";
    }
    if (response.additionalData.tmsLoadNumber === null) {
      response.additionalData.tmsLoadNumber = "-";
    }
    if (this.orderDetailHeader.length == 0) {
      if (status == 'Delivered' || status == 'In Transit') {
        this.orderDetailHeader = carrier_name + " | " + response.additionalData.tmsLoadNumber;
      } else if (status == "Not Scheduled" || status == "Unallocated") {
        this.orderDetailHeader = "Order " + status + " - Tracking details N/A";
      } else {
        this.orderDetailHeader = "Order " + status;
      }
    }
    this.totalPieces = response.additionalData.totalPieces;
  }
}


