import { Router, ActivatedRoute } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { PlatformLocation } from '@angular/common'
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AlertComponent } from 'ngx-bootstrap/alert/alert.component';
import { Menu } from '../../view/page-template/new-header/modal/menu';
import { StorageService } from '../../service/storage.service';



@Injectable({
	providedIn: 'root'
})
export class GlobalService {

	workflow: boolean = false;
	appReject: boolean = true;
	repReview: boolean = true;
	arReview: boolean = false;
	showAction: boolean = true;
	isARuser: boolean = false;
	isLogistic: boolean = false;
	isLogisticEdit: boolean = false;
	isLogisticApprove: boolean = true;
	isAREdit: boolean = false;
	onboardApplicationStatus;
	submitApp: boolean = false;
	isDelete: boolean = false;
	onboardApp: any = {};
	logoUrl: string;
	sideNavOpen = true;
	company: string;
	alerts: any[] = [];
	loading = false;
	apiRoot: string = environment.ENV_URL + "/" + environment.UAA_SERVICE_PREFIX;
	logoAPI: string = environment.ENV_URL + environment.FORWARD_SLASH + environment.CUSTOMER_SERVICE_PREFIX;
	pulldown: string = environment.ENV_URL + environment.APPLICATION_SHARED + environment.APPLICATION_PULLDOWN;
	//apiRoot:string = "http://localhost:9002/"
	adminMenu: Menu[] = [];
	displayMenu: Menu[] = [];
	permissionList: Menu[] = [];
	selectedRole: string;
	firstName: string;
	lastName: string;
	userEmail: string;
	header: string;
	userDetail: any = {};
	auditLogDetails: any = {};
	menuList: any = [];
	url = '';
	isProfileEditor: boolean = false;
	isImpersonateView: any;
	logisticStatus: boolean = false;
	userId: any;
	isOktaActive: boolean = false;
	USER_URL = environment.ENV_URL + "/" + environment.UAA_SERVICE_PREFIX;
	ALERT_URL = environment.ENV_URL + "/" + environment.ALERT_PREFIX;
	AUDIT_URL = environment.ENV_URL + "/" + environment.AUDIT_PREFIX;
	userList: any[];
	displayHeader:any;
  	shipInfoEdit: boolean = false;
	dropShipAccount:boolean = false;

	constructor(private http: HttpClient, 
				public router: Router, 
				private location: PlatformLocation,
				private storageService: StorageService) 
	{
		this.logoUrl = '../../../../assets/images/logo.png';
		location.onPopState(() => {
			let userDetails = this.storageService.getItem("userDetails")
			this.userDetail = JSON.parse(userDetails);
			let menuList = this.userDetail.permissionList;
			menuList.forEach(element => {
				element.subMenus.forEach(menu => {
					if (menu.route === window.location.pathname.split("/")[2]) {
						this.displayHeader = menu.displayText;
					}
				})
			})
		});
	}

	ngOnInit() {
	}

	toggleSideNav() {
		this.sideNavOpen = !this.sideNavOpen;
	}

	copyVariables(selectedObject: any, editedObject: any) {
		const keys: string[] = Object.keys(selectedObject);
		for (const key of keys) {
			editedObject[key] = selectedObject[key];
		}
		return editedObject;
	}

	addAlert(type: string, message: string): void {
		// primary, secondary, success, danger, warning, info, light, dark
		var duplicate = false;
		if (this.alerts.length > 0) {
			this.alerts.forEach((element => {
				if (element.msg === message) {
					duplicate = true;
					return;
				}
			}));
		} 
		
	 if(!duplicate)	{
		this.alerts.push({
			type: type,
			msg: message,
			timeout: 2000
		})

	 }
		
	}

	getUserInfo() {
		this.isOktaActive =  true;
		this.loading = this.showLoader();
		let userDetails = this.storageService.getItem("userDetails");
		if (userDetails != undefined) {
			this.constructMenuData(JSON.parse(userDetails));
		} else {
			this.showLoader();
			this.http.get(this.apiRoot + 'user').subscribe((result: any) => {
				if (result.hasError) {
					this.router.navigate(['/login']);
				} else {
					this.constructMenuData(result);
				}
			}, err => {
				this.router.navigate(['/login']);
			}
			);
		}
	}

	getUserDetails():any{
		return JSON.parse(this.storageService.getItem("userDetails"));
	}

	// To construct menu details
	constructMenuData(res) {
		this.menuList = [];
		this.permissionList = [];
		this.firstName = res.firstName;
		this.lastName = res.lastName;
		this.company = res.company;
		this.selectedRole = res.role;
		this.userDetail = res;
		this.userId = res.id;
		this.storageService.setItem("role", this.userDetail.role)
		// According to new CR HubSpot tile are removed
		this.displayMenu = res.permissionList.map(obj => ({
			...obj,
			subMenus: obj.subMenus ? obj.subMenus.filter(subMenu => subMenu.name !== "HubSpot") : []
		})).filter(item => item.subMenus.length > 0);
		
		this.menuList = JSON.parse(JSON.stringify(this.displayMenu));
		if (this.storageService.getItem("logoUrl") != undefined && this.storageService.getItem("userList")) {
			this.logoUrl = this.storageService.getItem("logoUrl");
			this.userList = JSON.parse(this.storageService.getItem("userList"));
		} else {
			this.findAllUserForImpersonate();
			this.getLogo(res.customerNbr);
		}
		this.constructDashbordTileData();
		this.storageService.setItem("userDetails", JSON.stringify(res));
		this.loading = this.hideLoader();
		this.userEmail = res.email;
		this.hideLoader();
	}

	constructDashbordTileData() {
		if (this.menuList != undefined) {
			this.menuList.forEach(element => {
				element.subMenus.forEach(menu => {
					// switch (menu.name) {
					// 	case "Catalog":
					// 		menu.name = "Customer Catalog";
					// 		break;
					// 	case "Onboarding ":
					// 		menu.name = "Customer Onboarding";
					// 		break;
					// 	case "Order Tracker":
					// 		menu.name = "Tracker";
					// 		break;
					// 	case "CBI Real":
					// 		menu.name = "Real";
					// 		break;
					// }
					// if (menu.name !== "User Manager" && menu.name !== "Check-in App (SMS)" && menu.name !== "Alert Manager"
					// 	&& menu.name !== "Audit Reports" && menu.name != "Bulk User Upload" && menu.route !== "dynamic-page"
					// 	&& menu.route !== "dynamic-menu") {
						if(menu.tileVisible){
						this.permissionList.push(menu)
					}
				});
			});
		}
	}
	onClosed(dismissedAlert: AlertComponent): void {
		this.alerts = this.alerts.filter(alert => alert !== dismissedAlert);
	}

	showLoader() {
		return this.loading = true;
	}

	hideLoader() {
		return this.loading = false;
	}

	getPulldownData(array) {
		return this.http.post(this.pulldown, array);
	}
	getLogo(customerId: string) {
		this.logoUrl = '../../../../assets/images/logo.png';
		this.http.get(this.logoAPI + "?customerNumber=" + customerId).subscribe((response: any) => {
			this.logoUrl = "../../../../assets" + response.logoUrl;
			this.loading = this.hideLoader();
			this.storageService.setItem("logoUrl", this.logoUrl);
		})
	}

	navigateToMenu(menuData) {
		//this.getEventType((menuData.name).toUpperCase());
		let menu = (menuData.name).toUpperCase();
		if ((menuData.route).includes("http")) {
			window.open(menuData.route, '_blank')
		} else {
			this.displayHeader = menuData.displayText;
			this.storageService.setItem("header", this.displayHeader)
			this.router.navigate(['/home/' + menuData.route]);
		}
		this.auditLogDetails.auditType = "application";
		this.auditLogDetails.eventType = menu;
		this.saveAuditLog(this.auditLogDetails);
	}

	

	findAllUserForImpersonate() {
		return this.http.get(this.USER_URL + "user/search/all").subscribe((val: any) => {
			this.userList = [];
			this.userList = val;
			this.storageService.setItem("userList", JSON.stringify(this.userList));
		});
	}

	findAllUnReadAlerts() {
		if (this.userId != undefined) {
			return this.http.get(this.ALERT_URL + "?userId=" + this.userId)
		} else {
			return null;
		}
	}

	markAsAlertRead(alertId) {
		return this.http.post(this.ALERT_URL + "/mark_as_read?alertId=" + alertId + "&userId=" + this.userId, {});
	}

	saveAuditLog(auditLogDetails) {
		auditLogDetails.userEmail = this.storageService.getItem("loggedInUser");
		this.http.post(this.AUDIT_URL, auditLogDetails).subscribe((response: any) => {
		})
	}
}
