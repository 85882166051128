import { ErrorHandler, Injectable } from '@angular/core';
import { GlobalService } from './global.service';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {

    constructor(public globalService: GlobalService) { }

    handleError(error: any): void {
        const chunkFailedMessage = /Loading chunk * failed/;
        console.log(error.stack);
        if (chunkFailedMessage.test(error.message)) {
            this.globalService.addAlert("danger","New build is deployed in 3 seconds page will get refreshed");
            setTimeout(() => {
                window.location.reload();
            }, 3000);
            
        }
    }
}