import {DatasetComponent} from '../server/dataset.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Ng2TableModule } from 'ng2-table/ng2-table';
import { PaginationModule } from 'ngx-bootstrap';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [DatasetComponent],
  imports: [
    CommonModule,
    Ng2TableModule,
    PaginationModule.forRoot(),
    FormsModule
  ],
  exports: [ DatasetComponent ]
})
export class DatasetModule { }
